/* Vice Section */
.sigparser-phase-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10vw;
}

.phase-2-details-container {
  text-align: center;
  align-items: center;
  padding-bottom: 2vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.phase-2-img-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.phase-2-proven-concept-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 5vw;
}

.phase-2-proven-concept-container * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.curved-arrow-top-left {
  width: 50%;
  height: 50%;
  transform: scaleY(-1);
}

.straight-arrow-down {
  transform: rotate(180deg);
  width: 65%;
  height: 65%;
}

.phase-2-proof-img {
  border-radius: 15px;
}

.sigparser-phase-2-video {
  border-radius: 15px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 1400px) {
  .phase-2-img-container {
    display: none;
  }

  .phase-2-details-container .grid-1-1-col-container {
    grid-template-columns: 1fr;
  }
}
