.contact-form {
  gap: 1rem;
  display: flex;
  margin-top: 1rem;
  text-align: left;
  flex-direction: column;
  padding: 0 20px;
}

.name-email-container {
  display: flex;
  gap: 1rem;
}

.name-email-container .form-group {
  flex: 1;
}

.form-group {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

input,
textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.contact-form button {
  margin: 0 auto;
  margin-top: 20px;
}

.contact-form button:hover {
  cursor: pointer;
}

.contact-footer-container {
  display: flex;
  margin-top: 4rem;
  justify-content: space-between;
}

.contact-footer-container div {
  gap: 1rem;
  display: flex;
  padding: 0 20px;
}

.contact-footer-container a {
  text-decoration: none;
  color: var(--text-100);
}

.social-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  fill: var(--text-100);
}

.social-icon:hover {
  fill: var(--accent-100);
}

@media only screen and (max-width: 1020px) {
  .contact-footer-container {
    gap: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 425px) {
  .contact-form {
    padding: 0;
  }

  .contact-footer-container div {
    padding: 0;
  }
}

@media only screen and (max-width: 321px) {
  .contact-footer-container div:nth-child(2) {
    flex-direction: column;
  }
}
