.hero-section {
  gap: 2vw;
  width: 95%;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  position: relative;
  grid-template-columns: 2fr 1fr;
  height: calc(100vh - 20vh);
  justify-content: center;
}

.hero-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-button-link {
  text-decoration: none;
  border: none;
  background-color: var(--accent-200);
}

.spacer {
  padding-bottom: 10px;
}

.button-row {
  gap: 40px;
  display: flex;
  padding-top: 10px;
}

@media only screen and (max-width: 768px) {
  .hero-section {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 472px) {
  .button-row {
    gap: 10px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 425px) {
  .hero-section h1 {
    line-height: 4rem;
    font-size: var(--fs-800);
  }

  .hero-section {
    margin-bottom: 80px;
  }

  .hero-image-container .global-circular-image {
    max-width: 200px;
  }

  .hero-section div > p {
    display: none;
  }
}
