/* Desktop CSS */
.projects-section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.projects-section-title {
  color: white;
}

/* Used to layout the three sections: prev, current, next */
.project-layout {
  display: grid;
  height: calc(100vh - 10vh);
  grid-template-columns: 15vw 1fr 15vw;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.transition-text {
  transform: rotate(270deg) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.transition-text:hover {
  color: var(--accent-200);
}

.current-project {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  transition: opacity 0.5s ease-out;
  opacity: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.current-project.show {
  opacity: 1;
}

.current-project-grid {
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.25fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.current-project-details {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-specifics-row {
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
}

.exp-skill-list {
  padding-left: 40px;
  list-style-type: circle !important;
}

.exp-skill-list li {
  list-style-type: inherit !important;
}

.project-eye-catcher-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile CSS */
.experience-mobile-container {
  padding: 10px;
  padding-top: 50px;
}

.experience-mobile-project {
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
}

.experience-mobile-project > p {
  line-height: 1.5rem;
}

.experience-mobile-project ul {
  margin-left: 20px;
}

/* Desktop Responsive CSS */
@media only screen and (max-width: 1110px) {
  .project-specifics-row p {
    line-height: 1.5rem;
  }

  .exp-skill-list {
    padding: 10px 0;
    list-style-type: circle !important;
  }

  .exp-skill-list li {
    line-height: 2rem;
    list-style-type: inherit !important;
  }

  .current-project-details button {
    margin-left: 0 !important;
  }

  .project-eye-catcher-container div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project-eye-catcher-container div svg {
    height: 60% !important;
  }
}

@media only screen and (max-width: 765px) {
  .current-project-grid {
    grid-template-columns: 1fr;
  }

  .project-eye-catcher-container div svg {
    height: 100% !important;
  }
}
