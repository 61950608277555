.fun-image-layout {
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  height: 700px;
}

.fun-image-layout .global-circular-image {
  max-width: 350px;
}

.image-stack {
  position: absolute;
  transition: transform 0.3s ease;
}

.image-stack:hover {
  transform: scale(1.1);
}

.image-1 {
  top: 0;
  left: 0;
  transform: rotate(-15deg);
  z-index: 3;
}

.image-2 {
  top: 160px;
  left: 220px;
  transform: rotate(10deg);
  z-index: 2;
}

.image-3 {
  top: 350px;
  left: 0;
  transform: rotate(-10deg);
  z-index: 1;
}

@media only screen and (max-width: 1275px) {
  .about-me-container {
    gap: 40px;
  }

  .fun-image-layout {
    height: 530px;
  }

  .fun-image-layout .global-circular-image {
    max-width: 250px;
  }

  .image-1 {
    top: 0;
    left: 0;
  }

  .image-2 {
    top: 160px;
    left: 160px;
  }

  .image-3 {
    top: 280px;
    left: 0;
  }
}

@media only screen and (max-width: 890px) {
  .about-me-container {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fun-image-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    position: relative;
  }

  .fun-image-layout .global-circular-image {
    position: static;
    max-width: 200px;
    margin-bottom: 20px;
  }

  .image-1,
  .image-2,
  .image-3 {
    transform: none;
    z-index: auto;
  }
}

@media only screen and (max-width: 600px) {
  .fun-image-layout .global-circular-image {
    max-width: 150px;
    margin-bottom: 15px;
  }
}
