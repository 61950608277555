.s3-content-container {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
}

/* Overview Section */
.s3-overview-container {
  display: flex;
  margin-bottom: 5vw;
  position: relative;
  isolation: isolate;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Ensures content is visible */
}

/* Light transparent background behind the text */
.s3-overview-container .content-grid {
  background-color: rgba(255, 255, 255, 0.3); /* Transparent white background */
  z-index: 1; /* Visible above the gradient */
  position: relative;
  padding: 80px; /* Apply padding to the inner content */
  width: 100%; /* Ensures the background stretches across the entire width */
  box-sizing: border-box; /* Ensures padding does not affect the width */
}

/* Background gradient */
.s3-overview-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(222, 220, 255, 1) 0%,
    rgba(67, 59, 255, 1) 100%
  );
  z-index: -1; /* Gradient stays behind everything */
}
