.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vw 0vw;
  gap: 20px;
  flex-direction: row;
}

.footer-socials {
  display: flex;
  gap: 20px;
}

.email-link {
  color: white;
  text-decoration: none;
}

.email-link:hover {
  color: var(--accent-100);
}

.footer-social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2vw;
  height: auto;
  color: white;
  fill: currentColor;
}
