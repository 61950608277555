/* Flex Containers */
.flex-container-1-1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

/* Grids */
.grid-1-1-col-container {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
}

.grid-1-1half-col-container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.grid-1half-1-col-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.grid-1-3-col-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;
  align-items: center;
}

/* Button */
.global-bttn {
  padding: 0.35rem 0.65rem;
  text-decoration: none;
  border-radius: 10px;
  width: fit-content;
  font-size: var(--fs-400);
  background-color: var(--accent-200);
}

.global-bttn:hover {
  cursor: pointer;
}

.global-bttn a {
  text-decoration: none;
}

.global-bttn p {
  color: var(--accent-100);
}

/* Padding */
.padding-bottom-m {
  padding-bottom: 5vw;
}

/* Text Styling */
.text-color-sec {
  color: var(--accent-100);
}

.text-color-bg {
  color: var(--bg-100);
}

.text-xs {
  font-size: 0.8vw;
  line-height: 1.5;
}

.text-s {
  font-size: 1vw;
  line-height: 1.8;
}

.text-m {
  font-size: 2.5vw;
  margin: 0;
  margin-bottom: 0;
  line-height: 1.6;
}

.text-l {
  font-size: 4vw;
  margin: 0;
  line-height: 1;
  font-weight: 150;
}

.text-xs,
.text-s,
.text-m {
  font-weight: 500;
}

.text-muted {
  opacity: 0.8;
}

.text-align-left {
  text-align: left;
}

/* Other */
.link-styles {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.global-link-2 {
  cursor: pointer;
  text-decoration: none;
  color: var(--accent-200);
}

.global-link-2:hover {
  color: var(--accent-100);
}

.global-circular-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 550px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #fff;
}

.global-circular-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.underline {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .global-circular-image {
    max-width: 400px;
  }
}

/* Old css */

@media (max-width: 1024px) {
  .paddingGlobal {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .textSizeXS {
    font-size: 1.5vw;
  }

  .textSizeS {
    font-size: 2.25vw;
  }

  .textSizeM {
    font-size: 3.5vw;
    margin: 0;
    margin-bottom: 0;
  }

  .textSizeL {
    font-size: 7vw;
    margin: 0;
    line-height: 1;
    font-weight: 150;
  }

  .textSizeM,
  .textSizeL {
    line-height: 1.25em;
  }

  .textSizeS {
    line-height: 1.5em;
  }
}

@media (max-width: 768px) {
  .paddingGlobal {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .textSizeXS {
    font-size: 1.5vw;
  }

  .textSizeS {
    font-size: 2.5vw;
  }

  .textSizeM {
    font-size: 4vw;
    margin: 0;
    margin-bottom: 0;
  }

  .textSizeL {
    font-size: 7vw;
    margin: 0;
    line-height: 1;
    font-weight: 150;
  }

  .textSizeM,
  .textSizeL {
    line-height: 1.25em;
  }

  .textSizeS {
    line-height: 1.5em;
  }
}

@media (max-width: 480px) {
  .paddingGlobal {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .textSizeXS {
    font-size: 3vw;
  }

  .textSizeS {
    font-size: 4vw;
  }

  .textSizeM {
    font-size: 6vw;
    margin: 0;
    margin-bottom: 0;
  }

  .textSizeL {
    font-size: 8vw;
    margin: 0;
    line-height: 1;
    font-weight: 150;
  }

  .textSizeS,
  .textSizeM,
  .textSizeL {
    line-height: 1.5em;
  }
}
