.scroll-indicator {
  top: 20vh;
  right: 2vw;
  height: 60vh;
  z-index: 1000;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  width: 2px;
  height: 100%;
  position: relative;
  background-color: white;
}

.dot {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  background-color: white;
  transition: background-color 0.3s ease;
  left: -7px; /* Centers the dot on the line */
}

.dot-1 {
  top: 20%;
}

.dot-2 {
  top: 40%;
}

.dot-3 {
  bottom: 40%;
}

.dot-4 {
  bottom: 20%;
}

.scroll-indicator .dot.active {
  background-color: #433bff;
}

.scroll-indicator .dot {
  cursor: pointer;
  pointer-events: auto;
}

@media only screen and (max-width: 375px) {
  .scroll-indicator {
    display: none;
  }
}
