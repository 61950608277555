.scroll-container {
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for other browsers (Firefox, etc.) */
.scroll-container {
  scrollbar-width: none; /* Firefox */
}

.home-scroll-section {
  display: flex;
  padding: 20px;
  min-height: 100vh;
  align-items: center;
}
